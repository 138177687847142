<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">ACADEMIC </span> COURSES
    </PageHeader>

    <v-row>
      <v-col>
        <v-btn
          class="ma-2 secondary--text font-weight-bold"
          to="/course_management/newcourse"
          :disabled="!getters_abilities.includes('new_course_access')"
          color="primary"
        >
          CREATE NEW COURSE
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-form ref="courseChangesForm">
            <!-- <resize-component>
              <template v-slot:table="tableProps"> -->
            <v-data-table
              fixed-header
              :headers="headers"
              :items="items"
              hide-default-footer
              :loading="loading"
              item-key="id"
              disable-pagination
            >
              <!-- <template v-slot:item.course_name="course">
								<v-edit-dialog
									single-line
									@save="saveCourseChangesBtn(course.item.id)"
									@open="course_name = course.item.course_name"
								>
									{{ course.item.course_name }}
									<template v-slot:input>
										<v-text-field
											clearable
											class="mt-5"
											v-model="course_name"
											label="Edit"
											outlined
											:rules="[(v) => !!v || 'Course Name is required']"
										></v-text-field>
									</template>
								</v-edit-dialog>
							</template> -->

              <template v-slot:item.credit_hours="hours">
                <v-edit-dialog
                  single-line
                  @save="saveCourseChangesBtn(hours.item.id)"
                  @open="credit_hrs = hours.item.credit_hours"
                >
                  {{ hours.item.credit_hours }}
                  <template v-slot:input>
                    <v-text-field
                      clearable
                      class="mt-5"
                      v-model.number="credit_hrs"
                      label="Edit"
                      outlined
                      type="number"
                      min="2"
                      max="6"
                      :rules="[(v) => !!v || 'Credit hours is required']"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.program="program">
                <v-btn color="primary" class="secondary--text">
                  <v-edit-dialog
                    large
                    @save="saveCourseChangesBtn(program.item.id)"
                    @open="openCourseProgram(program.item)"
                  >
                    Program(s)
                    <template #input>
                      <v-select
                        chips
                        :disabled="
                          !getters_abilities.includes('edit_course_access')
                        "
                        deletable-chips
                        :menu-props="{ top: true, offsetY: true }"
                        class="pt-3"
                        outlined
                        full-width
                        multiple
                        :items="getters_program"
                        item-text="program_name_certificate"
                        item-value="id"
                        label="Program"
                        placeholder="Program(s)"
                        v-model="basket"
                        :rules="[(v) => !!v || 'Program is required']"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggle">
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  basket.length > 0 ? 'indigo darken-4' : ''
                                "
                              >
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template #append> </template>
                      </v-select>
                    </template>
                  </v-edit-dialog>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <span class="subheading font-weight-bold">
                  No Course available
                </span>
              </template>
            </v-data-table>
            <v-card v-if="getters_courses.length" v-intersect="intersected">
              <v-progress-linear
                :indeterminate="true"
                v-if="loadingScroll"
              ></v-progress-linear>
            </v-card>
            <!-- </template>
            </resize-component> -->
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import {
    computed,
    getCurrentInstance,
    provide,
    reactive,
    toRef,
    toRefs,
  } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  // import ResizeComponent from "@/components/slots/ResizeComponent.vue";

  export default {
    components: { PageHeader, Response /* ResizeComponent */ },
    setup() {
      const vm = getCurrentInstance();
      const { getCourses, getProgram, saveChangesCourse, signOut } = useActions([
        "getCourses",
        "signIn",
        "getProgram",
        "saveChangesCourse",
        "signOut",
      ]);

      const {
        getters_courses,
        getters_program,
        getters_courses_meta,
        getters_abilities,
      } = useGetters([
        "getters_courses",
        "getters_program",
        "getters_courses_meta",
        "getters_abilities",
      ]);

      getProgram().catch((e) => {
        actionResponse.value = true;
        msgHeader.value = "Error";
        msgIcon.value = "mdi-close-circle";
        color.value = "error";

        switch (e.response.status) {
          case 423:
            msgBody.value = e.response.data.message;
            signOut().then(() => {
              vm.proxy.$router.replace({
                name: "Login",
              });
            });
            break;

          case 403:
            msgBody.value = e.response.data.message;
            break;

          default:
            msgBody.value = "Try Again Later Or Call To System Administrator";
            break;
        }
      });

      let courseHeaders = reactive({
        adminPassword: "",
        isadmin: true,
        checkAdmin: "",
        basket: [],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        headers: [
          { text: "COURSE CODE ", value: "course_code" },
          { text: "COURSE NAME", value: "course_name" },
          {
            text: "CREDIT HOURS",
            value: "credit_hours",
            align: "center",
          },
          { text: "PROGRAM(s)", value: "program" },
        ],
        page: 1,
        loadingScroll: false,
        isIntersecting: false,
        items: [],
        color: "",
      });

      const courseChangesForm = reactive({
        course_name: "",
        credit_hrs: "",
        loading: true,
        course_id: "",
      });

      const basket = toRef(courseHeaders, "basket");

      const {
        msgHeader,
        msgBody,
        msgIcon,
        color,
        actionResponse,
        page,
        loadingScroll,
        isIntersecting,
        items,
      } = toRefs(courseHeaders);

      const saveCourseChangesBtn = (course_id) => {
        actionResponse.value = false;
        if (
          vm.proxy.$refs.courseChangesForm.validate() &&
          getters_abilities.value.includes("edit_course_access")
        ) {
          let newchanges = {
            id: course_id,
            pivot: basket.value,
            course_name: courseChangesForm.course_name,
            credit_hours: courseChangesForm.credit_hrs,
          };

          saveChangesCourse(newchanges)
            .then(() => {
              courseChangesForm.loading = false;
              actionResponse.value = true;
              msgBody.value = "Course is successfully updated";
              msgHeader.value = "Success";
              msgIcon.value = "mdi-check-circle";
              color.value = "success";
              /*  
                            getCourses()
                              .then(() => {
                                courseChangesForm.loading = false;
                                actionResponse.value = true;
                                msgBody.value = "Course is successfully updated";
                                msgHeader.value = "Success";
                                msgIcon.value = "mdi-check-circle";
                                color.value = "success";
                              })
                              .catch((e) => {
                                actionResponse.value = true;
                                msgHeader.value = "Error";
                                msgIcon.value = "mdi-close-circle";
                                color.value = "error";
                                switch (e.response.status) {
                                  case 423:
                                    msgBody.value = e.response.data.message;
                                    signOut().then(() => {
                                      vm.proxy.$router.replace({
                                        name: "Login",
                                      });
                                    });
                                    break;
                                  case 403:
                                    msgBody.value = e.response.data.message;

                                    break;

                                  default:
                                    msgBody.value = "Try Again Later Or Call The Administrator";

                                    break;
                                }
                              })
                              .finally(() => (actionResponse.value = true)); 
                            */
            })
            .catch((e) => {
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              color.value = "error";
              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;
                case 403:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value = "Try Again Later Or Call The Administrator";
                  break;
              }
            })
            .finally(() => (actionResponse.value = true));
        } else {
          setTimeout(() => {
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            msgBody.value = "THIS ACTION IS UNAUTHORIZED";
          });
        }
      };

      provide("color", color);

      const openCourseProgram = (item) => {
        basket.value = [];
        getters_program.value.forEach((program) => {
          item.programs.forEach((itemprg) => {
            if (program.id === itemprg.id && !basket.value.includes(program.id)) {
              basket.value.push(program.id);
            }
          });
        });
      };

      getCourses()
        .then(() => {
          courseChangesForm.loading = false;
          items.value = getters_courses.value;
        })
        .catch((e) => {
          actionResponse.value = true;
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;
            case 403:
              msgBody.value = e.response.data.message;

              break;

            default:
              msgBody.value = "Try Again Later Or Call The Administrator";

              break;
          }
        });

      const selectAll = computed(() => {
        return getters_program.value.length === basket.value.length;
      });

      const selectSome = computed(() => {
        return (
          basket.value.length > 0 &&
          basket.value.length < getters_program.value.length
        );
      });

      const icon = computed(() => {
        if (selectAll.value) return "mdi-checkbox-marked";
        if (selectSome.value) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      });

      const toggle = () => {
        if (selectAll.value) {
          basket.value = [];
        } else {
          basket.value = [];
          getters_program.value.forEach((i) => basket.value.push(i.id));
        }
      };

      const intersected = (entries) => {
        isIntersecting.value = entries[0].isIntersecting;

        if (!isIntersecting.value) {
          return;
        }
        loadingScroll.value = true;

        if (
          getters_courses_meta.value.current_page >=
          getters_courses_meta.value.last_page
        ) {
          loadingScroll.value = false;
          return;
        }

        page.value++;
        getCourses(page.value).then(() => {
          loadingScroll.value = false;
          items.value = [...new Set([...items.value, ...getters_courses.value])];
        });
      };

      return {
        ...toRefs(courseHeaders),
        ...toRefs(courseChangesForm),
        saveCourseChangesBtn,
        getters_program,
        openCourseProgram,
        icon,
        toggle,
        intersected,
        getters_courses,
        getters_abilities,
      };
    },
  };
</script>
